import { render, staticRenderFns } from "./IssueDelete.vue?vue&type=template&id=53fab971&scoped=true"
import script from "./IssueDelete.vue?vue&type=script&lang=js"
export * from "./IssueDelete.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53fab971",
  null
  
)

export default component.exports